import React from "react";
import { graphql } from "gatsby";
import { Container, Grid } from "@material-ui/core";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { WithSearch, SearchProvider, Results, SearchBox, Sorting, Paging, PagingInfo } from "@elastic/react-search-ui";
import { Layout as SearchLayout } from "@elastic/react-search-ui-views";
import generateFrontendPath from "../util/generateFrontendPath.js"
import removeTags from "../util/removeTags.js"
import ReactHtmlParser from 'react-html-parser';
import BoardHeader from "../components/BoardHeader";
import BoardCategoryCard from "../components/BoardCategoryCard";

function board({ data }) {
	const connector = new AppSearchAPIConnector({
		searchKey: process.env.GATSBY_ELASTIC_SEARCH_KEY,
		engineName: process.env.GATSBY_ELASTIC_KB_ENGINE_NAME,
		endpointBase: process.env.GATSBY_ELASTIC_ENDPOINT_BASE,
		cacheResponses: false
	});

	const config = {
		apiConnector: connector,
		searchQuery: {
			disjunctiveFacets: [],
			disjunctiveFacetsAnalyticsTags: ["Ignore"],
			search_fields: {
				title: {},
				body: {},
				files: {}
			},
			result_fields: {
				title: {
					raw: {}
				},
        type: {
          raw: {}
        },
        status: {
          raw: {}
        },
        drupal_internal__nid: {
          raw: {}
        },
				body: {
					raw: {},
          snippet: {
						size: 300,
						fallback: true
          } 
				},
        files: {
          raw: {}
        }
			},
			facets: {
			}
		},
		hasA11yNotifications: true,
		a11yNotificationMessages: {
			searchResults: ({ start, end, totalResults, searchTerm }) =>
				`Searching for "${searchTerm}". Showing ${start} to ${end} results out of ${totalResults}.`
		},
		alwaysSearchOnInitialLoad: true
	};

  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ searchTerm, setSearchTerm, results }) => ({
          searchTerm,
          setSearchTerm,
          results
        })}
      >
        {({ searchTerm, setSearchTerm, results }) => {                              
          return (
            <> 
              <BoardHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              <div id="board-categories">
                <Container>
                  <Grid container spacing={4}>
                    {results.map(
                      (r) => {
                        let identifier = null;
                        switch (r.type?.raw) {
                          case "node--knowledge_base_articles":
                            identifier = r.drupal_internal__nid?.raw;
                            break;
                        }
                        let path = generateFrontendPath(r.type?.raw, r.title?.raw, identifier); 
                        let excerpt = "";
                        let lowerStr = "";
                        for (const field in r) {
                          if (field == "drupal_internal__nid") continue;
                          lowerStr = r[field].raw ? r[field].raw.toLowerCase() : ""
                          if (lowerStr.includes(searchTerm.toLowerCase())) {
                            excerpt = removeTags(unescape(r[field].raw));
                            break;
                          }
                        }
                        if (excerpt == "") excerpt = r.body ? removeTags(unescape(r.body.raw)) : excerpt;
                        return path && !r.title.raw.toLowerCase().includes("schema") && r.status.raw == "true" && r.type?.raw === "node--knowledge_base_articles" ? (
                          <Grid key={r.drupal_internal__nid.raw} item xs={12} md={4}>
                            <BoardCategoryCard title={r.title.raw} excerpt={excerpt} path={path} />
                          </Grid>
                        ) : (<></>)
                      }
                    )}
                  </Grid>
                </Container>
              </div>
            </>
          )
        }}
      </WithSearch>
    </SearchProvider>
  )
}

export default board;

export const query = graphql`
  {
    allTaxonomyTermResourceType(sort: { order: ASC, fields: name }) {
      edges {
        node {
          drupal_id
          name
          field_description
          fields {
            slug
          }
        }
      }
    }
  }
`;
