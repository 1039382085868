import { navigate } from "gatsby";
import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    textDecoration: "none",
    height: "100%",
    padding: "1.5em 1.7em",
    "&:hover, &:active, &:focus": {
      "& h2": {
        color: theme.palette.secondary.main,
      },
    },
    "& h2": {
      marginBottom: "0.5em",
      "&::after": {
        content: "' '",
        backgroundColor: theme.palette.secondary.light,
        display: "block",
        height: 4,
        marginTop: "0.45em",
      },
    },
  },
}));

function BoardCategoryCard({ title, excerpt, path }) {
  const classes = useStyles();
  return (
    <Paper
      component="a"
      href={path}
      className={classes.root}
      onClick={(e) => {
        e.preventDefault();
        navigate(path);
      }}
    >
      <Typography variant="h3" component="h2">
        {title}
      </Typography>
      {excerpt && <Typography>{excerpt}</Typography>}
    </Paper>
  );
}

export default BoardCategoryCard;
